import {
  ConsentBanner,
  initializeControls,
} from '@atlassian/browser-storage-controls';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl-next';

const CookieConsentBanner = ({ onHide }: { onHide: () => void }) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeControls({
      analyticsEnabled: false,
      product: 'statuspage',
      webStorageEnabled: true,
    });
  }, []);

  return (
    <IntlProvider locale={'en'}>
      <ConsentBanner
        analyticsEnabled={false}
        product="statuspage"
        localConsentMode
      />
    </IntlProvider>
  );
};

export default CookieConsentBanner;
