import React from 'react';
import PropTypes from 'prop-types';
import Querier from 'querier';
import cx from 'classnames';
import _ from 'lodash';
import ItemsSelector from 'components/items_selector/items_selector';
import IncidentHistory from './incident_history';
import HistoryPaginator from './history_paginator';

class HistoryIndex extends React.Component {
  _fetchFiltered = _.throttle(
    () => {
      Querier.fetch(
        { filter: this.state.filterComponentIds.join(',') },
        { merge: true },
      ).then((data) => {
        this.setState({
          components: data.components,
          componentFilter: data.component_filter,
          months: data.months,
        });
      });
    },
    250,
    { leading: false, trailing: true },
  );

  constructor(props) {
    super(props);

    this.state = {
      components: props.components,
      componentFilter: props.component_filter,
      filterComponentIds: [],
      months: props.months,
      showComponentFilter: props.show_component_filter,
      showItemsSelector: false,
    };
  }

  _toggleComponentSelector() {
    this.setState({ showItemsSelector: !this.state.showItemsSelector });
  }

  _changeComponent(items) {
    const ids = [];
    items.forEach((item) => {
      if (item.selected) {
        ids.push(item.id);
      }
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          if (child.selected) {
            ids.push(child.id);
          }
        });
      }
    });

    this.setState({ filterComponentIds: ids });
    this._fetchFiltered();
  }

  _renderComponentSelector() {
    const preselectedItemIds = this.state.componentFilter || [];

    return (
      <ItemsSelector
        items={this.props.components}
        className="component-items-selector"
        showFooter={false}
        onClose={this._toggleComponentSelector.bind(this)}
        onToggle={this._changeComponent.bind(this)}
        preselectedItemIds={preselectedItemIds}
      />
    );
  }

  _renderComponentSelectorButton() {
    const { showComponentFilter, showItemsSelector } = this.state;
    if (showComponentFilter) {
      return (
        <div className="component-selector">
          <span
            aria-expanded={showItemsSelector}
            aria-controls="component-items-selector"
            className={cx('show-filter', 'color-secondary', 'border-color', {
              open: showItemsSelector,
            })}
            role="button"
            tabIndex={0}
            onClick={this._toggleComponentSelector.bind(this)}
            onKeyPress={this._toggleComponentSelector.bind(this)}
          >
            Filter Components
          </span>
          {showItemsSelector ? this._renderComponentSelector() : ''}
        </div>
      );
    }
  }

  _handlePagination(responseData) {
    this.setState({
      components: responseData.components,
      componentFilter: responseData.component_filter,
      months: responseData.months,
    });
  }

  _renderMonths() {
    return this.state.months.map((month) => (
      <div className="month" key={`${month.name}${month.year}`}>
        <h4 className="month-title font-largest border-color">
          {month.name}&nbsp;<var data-var="year">{month.year}</var>
        </h4>
        <div className="month-content">
          <IncidentHistory incidents={month.incidents} />
        </div>
      </div>
    ));
  }

  render() {
    const startDateMonth = this.state.months[2].name;
    const startDateYear = this.state.months[2].year;
    const endDateMonth = this.state.months[0].name;
    const endDateYear = this.state.months[0].year;

    return (
      <div className="history-backpage">
        <div className="history-header">
          {this._renderComponentSelectorButton()}
          <HistoryPaginator
            startDateMonth={startDateMonth}
            startDateYear={startDateYear}
            endDateMonth={endDateMonth}
            endDateYear={endDateYear}
            responseHandler={this._handlePagination.bind(this)}
          />
        </div>
        <div className="months-container">{this._renderMonths()}</div>
      </div>
    );
  }
}

HistoryIndex.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  component_filter: PropTypes.array,
  months: PropTypes.arrayOf(PropTypes.object).isRequired,
  show_component_filter: PropTypes.bool.isRequired,
};

export default HistoryIndex;
